<template>
  <div>
    <div @click="$router.push('/catalog')" class="back-btn">
      <v-icon class="ml-0">chevron_left</v-icon>назад
    </div>
    <h1 class="display-1">Единицы измерения</h1>
    <v-divider class="mt-2 mb-8"></v-divider>

    <!-- measurement_unit creating dialog -->
    <v-dialog
      v-model="dialogModal"
      persistent
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          depressed
          color="light-grey"
          v-bind="attrs"
          v-on="on"
          class="mb-4"
          @click="openMeasurement_unit"
        >
          + Добавить единицу измерения
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <h1 class="display-1" v-if="measurement_unit.id">Редактирование единицы измерения</h1>
          <h1 class="display-1" v-else>Новая единица измерения</h1>
        </v-card-title>
        <v-divider class="mb-8"></v-divider>

        <v-card-text class="pb-0">
          <v-text-field label="Название" outlined v-model="measurement_unit.name"></v-text-field>
          <v-textarea label="Описание" outlined v-model="measurement_unit.description"></v-textarea>
        </v-card-text>

        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <div v-if="measurement_unit.id">
            <v-btn depressed x-large color="success" @click="updateMeasurement_unit" class="mr-3">Обновить единицу измерения</v-btn>
            <v-btn depressed x-large color="light-grey" @click="closeMeasurement_unit" class="mr-3">Закрыть</v-btn>
          </div>
          <div v-else>
            <v-btn depressed x-large color="success" @click="createMeasurement_unit" class="mr-3">Создать единицу измерения</v-btn>
            <v-btn depressed x-large color="light-grey" @click="closeMeasurement_unit" class="mr-3">Закрыть</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- / measurement_unit creating dialog -->

    <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
    <v-container class="tree-box" fluid>
      <v-row class="tree-header">
        <v-col cols="4">
          Название
        </v-col>
        <v-col cols="7">
          Описание
        </v-col>
      </v-row>

      <v-row class="tree-row" v-for="item in measurement_units" :key="item.id">
        <v-col cols="4">
          {{ item.name }}
        </v-col>
        <v-col cols="7">
          {{ item.description }}
        </v-col>
        <v-col cols="1" class="text-right">
          <div class="actions">
            <v-icon
              small
              class="mr-2"
              @click="openMeasurement_unit(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              small
              @click="deleteMeasurement_unit(item)"
            >
              mdi-delete
            </v-icon>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div class="text-left mt-4">
      <v-pagination
        depressed
        v-model="paginator.current_pages"
        :length="paginator.total_pages"
        @input="getMeasurement_units()"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: 'measurement_units',
  data() {
    return {
      dialogModal: false
    }
  },
  methods: {
    getMeasurement_units() {
      this.$store.dispatch('getMeasurement_units')
    },
    createMeasurement_unit () {
      this.dialogModal = false
      this.$store.dispatch('createMeasurement_unit')
    },
    updateMeasurement_unit () {
      this.dialogModal = false
      this.$store.dispatch('updateMeasurement_unit')
    },
    openMeasurement_unit (item) {
      this.dialogModal = true

      if (item.id) {
        this.$store.commit('setMeasurement_unit', item)
      } else {
        this.$store.commit('setMeasurement_unit', {})
      }
    },
    deleteMeasurement_unit (item) {
      this.dialogModal = false
      confirm('Вы уверены что хотите удалить единицу измерения? Вернуть ее уже будет нельзя!') && this.$store.dispatch('deleteMeasurement_unit', item)
    },
    closeMeasurement_unit () {
      this.dialogModal = false
    },
  },
  computed: {
    measurement_units () {
      return this.$store.getters.measurement_units.data
    },
    measurement_unit () {
      return this.$store.getters.measurement_unit
    },
    paginator() {
      return this.$store.getters.measurement_units.paginator
    },
    loading () {
      return this.$store.getters.loading
    }
  },
  created() {
    this.getMeasurement_units()
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
</style>
